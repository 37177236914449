export const FEATURE_FLAG_ENV_MAP = {
  FEATURE_FLAG_PHASE_MANAGEMENT: process.env.FEATURE_FLAG_PHASE_MANAGEMENT,
  FEATURE_FLAG_VOC: process.env.FEATURE_FLAG_VOC,
  FEATURE_FLAG_COMMUNICATION_REPORT_STANDARD_REVENUE:
    process.env.FEATURE_FLAG_COMMUNICATION_REPORT_STANDARD_REVENUE,
  FEATURE_FLAG_DATA_SYNC_STATUS: process.env.FEATURE_FLAG_DATA_SYNC_STATUS,
  FEATURE_FLAG_SALES_MAP_DISTANCE_FILTER:
    process.env.FEATURE_FLAG_SALES_MAP_DISTANCE_FILTER,
  FEATURE_FLAG_SALES_MAP_TO_REPORT:
    process.env.FEATURE_FLAG_SALES_MAP_TO_REPORT,
} as const;

export const isFeatureFlagEnabled = (key: keyof typeof FEATURE_FLAG_ENV_MAP) =>
  FEATURE_FLAG_ENV_MAP[key] === 'true';
